// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-ausstellung-js": () => import("./../../../src/components/templates/ausstellung.js" /* webpackChunkName: "component---src-components-templates-ausstellung-js" */),
  "component---src-components-templates-dozent-js": () => import("./../../../src/components/templates/dozent.js" /* webpackChunkName: "component---src-components-templates-dozent-js" */),
  "component---src-components-templates-objekt-js": () => import("./../../../src/components/templates/objekt.js" /* webpackChunkName: "component---src-components-templates-objekt-js" */),
  "component---src-components-templates-person-js": () => import("./../../../src/components/templates/person.js" /* webpackChunkName: "component---src-components-templates-person-js" */),
  "component---src-components-templates-quellentexte-js": () => import("./../../../src/components/templates/quellentexte.js" /* webpackChunkName: "component---src-components-templates-quellentexte-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ausstellungen-js": () => import("./../../../src/pages/ausstellungen.js" /* webpackChunkName: "component---src-pages-ausstellungen-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-personen-js": () => import("./../../../src/pages/personen.js" /* webpackChunkName: "component---src-pages-personen-js" */)
}

