import React, {useEffect}  from "react"
import Transition from "../components/transition"
import Header from "../components/header"

import "../components/layout.css"

const TemplateWrapper = ({ children, location }) => {
  useEffect(()=>{
    window.oncontextmenu = (e) => {
      e.preventDefault();
    }
  })
  return (
    <>
      <a class="skip-link" href="#maincontent">Skip to main</a>
      <Header/>
      <div>
          <Transition location={location}>{children}</Transition>
      </div>
    </>
  )
}

export default TemplateWrapper
