import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { withTrans } from "../i18n/withTrans"
import LanguageSwitcher from "./languageswitcher"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ location, t, i18n }) => (
  <header>
    <p  className="headerP" style={{top:"20px", left:"20px",}}>
        <Link to="/ausstellungen">
            {t("ausstellungen")}
        </Link>
    </p>
    <p className="headerP" style={{top:"20px", right:"20px",}}>
        <Link to="/personen/">
            {t("personen")}
        </Link>
    </p>
    <p className="headerP" style={{bottom:"20px", left:"20px",}}>
        <Link to="/quellentexte">
            {t("quellentexte")}
        </Link>
    </p>
    <p className="headerP" style={{bottom:"20px", right:"20px",}}>
        <Link to="/info">
            {t("info")}
        </Link>
    </p>
    <Link to="/">
        <div style={{left:"20px", top:"calc(50vh - 10px)", zIndex:"3", position: "fixed",}}>
            <StaticImage src="../images/home.png" style={{width:"20px",}} className="hoverIcon" placeholder="none" loading="eager" alt="home"/>
        </div>
    </Link>
    <LanguageSwitcher/>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default withTrans(Header)
