import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"


const LanguageMenu = (props) => {
    const { i18n } = useTranslation()

    const [values, setValues] = useState({
        language: 'de'
    });

    function handleChange(str) {
      try {
        document.getElementsByClassName("langSwitchImg")[0].classList.remove("animate");
        document.getElementsByClassName("langSwitch")[0].classList.remove("animate");
      } catch (e) {}

        i18n.changeLanguage(str)

        setValues(oldValues => ({
            ...oldValues,
            language: str,
        }));
    }

    return(
        <div style={{right: "0", paddingRight:"20px", top:"calc(50vh - 10px)", zIndex:"3", position: "fixed",}} className="langSwitch hoverIcon">
            <p
                style={{top: "-100%", paddingBottom:"30px", zIndex:"2"}}
                className="langSwitchP"
                onClick={()=>{handleChange("de")}}>DE</p>
            <p
                style={{top: "90%" , zIndex:"2"}}
                className="langSwitchP"
                onClick={()=>{handleChange("en")}}>EN</p>
            <StaticImage src="../images/language.png" style={{width:"20px",}} className="langSwitchImg" placeholder="blurred" loading="eager"/>
        </div>
    )
}

export default LanguageMenu
